<template>
  <default-layout>
    <portal to="pageHeaderRight">
      <contact-dropdown/>
      <router-button
        v-if="$store.state.is_godfather"
        routeName="BrandDescribeEdit"
        routeParam="welcome"
        :text="$t('app.edit')"
        icon="icon-icon-edit-pencil-line"
        cssClass="ab-button--white"
      ></router-button>
    </portal>
    <portal to="sidebarContent">
      <div class="sidebar-left__content-header">
        {{ $t('app.navigation') }}
      </div>
      <div class="sidebar-left__menu">
        <ul>
          <li>
            <router-button
              routeName="Dashboard"
              :text="$t('navigation.dashboard')"
            ></router-button>
          </li>
          <li>
            <router-button
              routeName="BrandList"
              :text="$t('navigation.brand')"
            ></router-button>
          </li>
          <li>
            <router-button
              routeName="Calendar"
              :text="$t('navigation.planning')"
            ></router-button>
          </li>
          <li>
            <router-button
              routeName="AssetList"
              :text="$t('navigation.assets')"
            ></router-button>
          </li>
        </ul>
      </div>
    </portal>

    <view-content-repeater
      :content-items="brandData.value"
    ></view-content-repeater>
  </default-layout>
</template>

<script>
import backend from '@/backend'
import ViewContentRepeater from '@/views/brand/components/ViewContentRepeater'

export default {
  name: 'WelcomePage',
  data () {
    return {
      brandData: {
        value: []
      }
    }
  },
  created () {
    backend.get(`${process.env.VUE_APP_URL}/pages/welcome/describe`).then(response => {
      this.brandData.value = response.data.value
      this.brandData.id = response.data.id
    })
  },
  components: {
    ViewContentRepeater
  }
}
</script>
